import React, { useState } from "react"
import styled from "styled-components"
import { CustomButton } from "../common/Button"
import PlanCard from "../common/PlanCard"

const appUrl = process.env.GATSBY_APP_URL
const PlanArray = [
  {
    name: "Free",
    price: "$0",
    priceY: "$0",
    seats: "1 user seat",
    reports: "3 reports / month",
    projects: "2 projects",
    aiCredits: "30 AI credits",
    keywordResearch: "2 keyword researches",
    keywordTracking: "3 keyword trackings",
    planDesc: "For those who first want to try Robinize",
    desc: "(0 dollars per report)",
    descDiscount: "(0 dollars per report)",
    button: "Get started",
    url: `${appUrl}/account/billing`,
    support: false,
    multi: false,
    hasPrice: true,
    isDiscount: true,
  },
  {
    name: "Individual",
    price: "$19",
    salePrice: "$13,3",
    priceY: "$199",
    seats: "1 user seat",
    reports: "10 reports / month",
    projects: "3 projects",
    aiCredits: "200 AI credits",
    keywordResearch: "10 keyword researches",
    keywordTracking: "50 keyword trackings",
    planDesc: "For solo content writers",
    desc: "(1.9 dollars per report)",
    descDiscount: "(1.65 dollars per report)",
    button: "Get started",
    url: `${appUrl}/account/billing`,
    support: false,
    multi: false,
    hasPrice: true,
    highlight: true,
  },
  {
    name: "Team",
    price: "$49",
    salePrice: "$34,3",
    priceY: "$499",
    planDesc: "For content writing teams",
    seats: "3 user seats",
    reports: "50 reports / month",
    projects: "20 projects",
    aiCredits: "1500 AI credits",
    keywordResearch: "40 keyword researches",
    keywordTracking: "200 keyword trackings",
    desc: "(0.98 dollars per report)",
    descDiscount: "(0.83 dollars per report)",
    button: "Get started",
    url: `${appUrl}/account/billing`,
    support: true,
    multi: true,
    hasPrice: true,
    highlight: true,
  },
  {
    name: "Enterprise",
    price: "Enterprise solution",
    priceY: "Enterprise solution",
    planDesc: "For large scale enterprises",
    reports: "Contact us for a quota",
    button: "Book a Meeting",
    seats: "",
    url: `${appUrl}/account/billing`,
    support: true,
    hasPrice: false,
  },
]

const Plans = ({ CTAClick }) => {
  const [isAnnual, setIsAnnual] = useState(false)
  const [isMounted, setIsMounted] = useState(false)

  const renderPlans = PlanArray.map((item, index) => (
    <PlanCard {...item} isAnnual={isAnnual} isMounted={isMounted} key={index} />
  ))

  const toggleClick = e => {
    e.preventDefault()
    setIsAnnual(!isAnnual)
    setIsMounted(!isMounted)
  }

  return (
    <Background>
      <MainWrapper>
        <Banner className="fade-up">
          <ContentWrapper>
            <BigTitle>
              Rank higher by improving your <br />
              content quality with Robinize
            </BigTitle>
            <GetStartedButton
              onClick={e => {
                e.preventDefault()
                CTAClick(`${appUrl}/register`, "Get started banner / Plans")
              }}
            >
              Try for free
            </GetStartedButton>
          </ContentWrapper>
        </Banner>
        <Header id="plans" className="fade-up">
          <Title>Plans and Pricing</Title>
          <Description>
            Spend less time researching and start producing high-quality SEO
            content that helps clients meet their targets
          </Description>
          <ToggleContainer>
            <ToggleWrapper onClick={toggleClick} toggle={isAnnual}>
              <Span toggle={isAnnual}>Monthly</Span>
              <AnnualSpan toggle={isAnnual}>Annual</AnnualSpan>
            </ToggleWrapper>
          </ToggleContainer>
        </Header>
        <CardsContainer className="fade-up">{renderPlans}</CardsContainer>
      </MainWrapper>
    </Background>
  )
}

export default Plans

const Banner = styled.div`
  width: 90%;
  max-width: 1480px;
  position: absolute;
  height: 200px;
  display: flex;
  justify-content: center;
  top: -6%;
  border-radius: 30px;
  background-color: ${({ theme }) => theme.colors.primary};

  @media (min-width: 783px) {
    top: -11%;
  }

  @media (min-width: 660px) {
    width: 80%;
  }

  @media (min-width: 1580px) {
    height: 280px;
    top: -14%;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 3rem 1.5rem;
  text-align: center;
  flex-direction: column;

  @media (min-width: 750px) {
    flex-direction: row;
    justify-content: space-around;
    padding: 3rem 1.8rem;
  }

  @media (min-width: 1500px) {
    justify-content: space-between;
    padding: 3rem 6rem;
  }
`

const BigTitle = styled.span`
  font-size: 1rem;
  color: white;
  font-family: InterBold;
  margin-bottom: 1rem;

  @media (min-width: 524px) {
    font-size: 1.3rem;
  }

  @media (min-width: 750px) {
    font-size: 1.3rem;
    margin-bottom: 0;
  }

  @media (min-width: 950px) {
    font-size: 1.9rem;
  }

  @media (min-width: 1500px) {
    font-size: 2.5rem;
  }
`

const GetStartedButton = styled(CustomButton)`
  max-width: 232px;
  max-height: 60px;
  height: 100%;
  width: 100%;
  padding: 19px 22.9px 18px 22.9px;
  box-shadow: 0 3px 5px 0 rgba(217, 83, 49, 0.15);
  background-color: #151836;
  border-radius: 8px;
  transition: all 0.3s ease-out;
  font-family: Inter500;
  will-change: background-color, color;

  &:hover {
    background-color: #fff;
    color: black;
  }
`

const Background = styled.section`
  background-color: #fcfcfc;
  width: 100%;
  position: relative;
`

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10rem 0px 5rem 0px;
  max-width: 1280px;
  margin: 0 auto !important;
  float: none !important;

  @media (max-width: 1334px) {
    padding-left: 10%;
    padding-right: 10%;
  }

  @media (min-width: 1584px) {
    padding-top: 15rem;
  }
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`

const Title = styled.h1`
  font-size: 2.5rem;
  font-family: InterBold;
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.fontBlack};
`

const Description = styled.span`
  font-size: 1.3rem;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.fontBlack};
`

const ToggleContainer = styled.div`
  margin-bottom: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const ToggleWrapper = styled.div`
  height: 50px;
  border-radius: 8px;
  background-color: lightgray;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
  gap: 2rem;
  position: relative;
  transition: all 0.35s ease-out 350ms;
  box-shadow: 0 3px 13px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:after {
    content: "";
    width: 55%;
    height: inherit;
    box-shadow: 0 3px 10px 0 rgba(78, 78, 78, 0.16);
    background-color: #fff;
    position: absolute;
    border-radius: 8px;
    left: 0;
    transition: all 0.6s cubic-bezier(0.88, -0.54, 0.29, 1.5);

    ${({ toggle, theme }) =>
      toggle ? `transform:translateX(90%);` : `transform: translateX(-2%);`};
  }
`

const Span = styled.span`
  font-size: 1rem;
  font-family: Inter500;
  color: ${({ toggle, theme }) =>
    toggle ? theme.colors.headlineWhite : theme.colors.accent};
  transition: all 0.6s cubic-bezier(0.88, -0.54, 0.29, 1.5);
  z-index: 2;
  cursor: pointer;
  margin: auto;
`

const AnnualSpan = styled(Span)`
  color: ${({ toggle, theme }) =>
    toggle ? theme.colors.accent : theme.colors.headlineWhite};
`

const CardsContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-direction: row;

  @media (max-width: 1300px) {
    flex-direction: column;
    gap: 3rem;
  }
`
