import React from "react"
import styled from "styled-components"
import { theme } from "../../theme"
import { StaticImage } from "gatsby-plugin-image"

const ResultsV2 = () => {
  return (
    <Background>
      <MainWrapper>
        <Title className="fade-up">
          Investing in SEO but not getting the results you want?
        </Title>
        <ContentWrapper className="fade-up">
          <GoogleContainer>
            <Span color="orange" left="-1.1rem">
              Make your content
              <br />
              <StyledSpan color={theme.colors.accent}>
                Google-friendly
              </StyledSpan>
            </Span>
            <StaticImage
              src="../../images/google.png"
              alt="google-illu"
              placeholder="blurred"
            />
          </GoogleContainer>
          <OrganicContainer>
            <Span color="green" left="2.3rem">
              Create new content that attracts
              <br />
              <StyledSpan color={theme.colors.primary}>
                organic traffic
              </StyledSpan>
            </Span>
            <StaticImage
              src="../../images/capgreen.png"
              alt="organic-illu"
              placeholder="blurred"
            />
          </OrganicContainer>
          <QualityContainer>
            <Span left="0.8rem">
              Improve the quality of your
              <br />
              <StyledSpan color={theme.colors.accentBlue}>
                existing content
              </StyledSpan>
            </Span>
            <StaticImage
              src="../../images/quality.png"
              alt="quality-illu"
              placeholder="blurred"
            />
          </QualityContainer>
        </ContentWrapper>
      </MainWrapper>
    </Background>
  )
}

export default ResultsV2

const Background = styled.section`
  max-height: 100%;
`

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5rem;
  
  @media (min-width: 1024px) {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  
`

const Title = styled.h1`
  text-align: center;
  font-size: 2rem;
  font-family: InterBold;
  margin-bottom: 10rem;
  color: ${({ theme }) => theme.colors.headlineWhite};
`

const ContentWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  justify-items: center;
  gap: 1rem;

  @media (min-width: 908px) {
    grid-auto-flow: column;
    gap: 5rem;
  }
`

const Span = styled.span`
  font-family: Inter;
  font-size: 1.1rem;
  height: 2rem;
  text-align: center;
  font-family: Inter500;
  position: relative;

  &:before {
    position: absolute;
    content: "";
    top: -3rem;
    left: ${({left}) => left};
    height: 150px;
    width: 200px;
    background: ${({ color }) =>
      color === "orange"
        ? "rgba(255,103,36,0.2)"
        : color === "green"
        ? "rgba(24, 197, 149, 0.2)"
        : "rgba(44, 150, 242, 0.2)"};
    box-shadow: ${({ color }) =>
      color === "orange"
        ? "0 0 99px 0 rgba(255,103,36,0.13)"
        : color === "green"
        ? "0 0 99px 0 rgba(24, 197, 149, 0.2)"
        : "0 0 99px 0 rgba(44, 150, 242, 0.13)"};
    border-radius: 90%;
    z-index: -1;
    filter: blur(3.5rem);
    pointer-events: none;

    @media (min-width:910px) {
      left: -0.5rem;
    }

    @media (min-width:1148px){
      left: ${({left}) => left};
    }
  }
`

const StyledSpan = styled(Span)`
  font-family: InterBold;
  color: ${({ color }) => color};

  &:before {
    background: none;
    box-shadow: none;
  }
`

const GoogleContainer = styled.div`
  height: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8rem;
  gap: 4rem;

  @media (min-width: 750px) {
    gap: 2rem;
  }

  @media (min-width: 1066px) {
    gap: 0rem;
  }
`

const OrganicContainer = styled(GoogleContainer)``

const QualityContainer = styled(GoogleContainer)``
