import { StaticImage } from "gatsby-plugin-image"
import React, { useRef, useState } from "react"
import styled from "styled-components"
import LightBox from "../LightBox/LightBox"
import RightArrow from "../../assets/arrow-right-long.svg"
import LeftArrow from "../../assets/arrow-left-long.svg"
import { theme } from "../../theme"
import { FadeIn } from "../../components/animations/FadeIn"

const HowItWorks = () => {
  const instructions = [
    {
      id: 1,
      title: "Keyword research",
      description:
        "Use our keyword research module to find out which words are worth trying to rank for",
    },
    {
      id: 2,
      title: "Detailed report",
      description:
        "Generate a report that provides all the information about what your top 20 competitors are writing to rank",
    },
    {
      id: 3,
      title: "AI Module",
      description:
        "Utilize our Al module to generate a new article, which combines the topics covered by your competitors with your unique personal touch",
    },
    {
      id: 4,
      title: "WordPress Integration",
      description:
        "Post the content to your website, either by copying it or through our WordPress integration",
    },
    {
      id: 5,
      title: "Rank Tracking",
      description:
        "Track your ranking for each keyword and observe the growth in your rankings",
    },
  ]

  const [activeInstruction, setActiveInstruction] = useState(1);
  const [scrolled, setScrolled] = useState(false);
  const howItWorksRef = useRef(null);

  const goNext = () => {
    if (activeInstruction === 5) {
      return
    }
    setActiveInstruction(prev => prev + 1)
  }

  const goBack = () => {
    if (activeInstruction === 1) {
      return
    }
    setActiveInstruction(prev => prev - 1)
  }

  const scrollToImage = (id) => {
    if(window.innerWidth <= 1334){
      setScrolled(true);
      setActiveInstruction(id);
      howItWorksRef.current?.scrollIntoView({ behavior: 'smooth' });
      let timeout = setTimeout(() => {
        setScrolled(false);
        clearTimeout(timeout);
      }, 500);
    }
  }

  const renderImage = () => {
    switch (activeInstruction) {
      case 1:
        return (
          <StaticImage
            quality={100}
            src="../../images/Step1.png"
            alt="keyword-research"
            width={891}
            height={579}
            layout="constrained"
            style={{ borderRadius: "12px", border: `1px solid ${theme.colors.gray + "30"}`}}
          />
        )
      case 2:
        return (
          <StaticImage
            quality={100}
            src="../../images/Step2.png"
            alt="detailed-report"
            width={891}
            height={579}
            layout="constrained"
            style={{ borderRadius: "12px" }}
          />
        )
      case 3:
        return (
          <StaticImage
            src="../../images/Step3.png"
            alt="ai-module"
            quality={100}
            width={891}
            height={579}
            layout="constrained"
            style={{ borderRadius: "12px" }}
          />
        )
      case 4:
        return (
          <StaticImage
            quality={100}
            src="../../images/Step4.png"
            alt="wordpress-integration"
            width={891}
            height={579}
            layout="constrained"
            style={{ borderRadius: "12px" }}
          />
        )
      case 5:
        return (
          <StaticImage
            quality={100}
            src="../../images/Step5.png"
            alt="rank-tracking"
            width={891}
            height={579}
            layout="constrained"
            style={{ borderRadius: "12px" }}
          />
        )
      default:
        return <></>
    }
  }

  return (
    <MainWrapper>
      <ContentWrapper>
        <Title className="fade-up">How it works</Title>
        <ContentBox>
          <LeftBox ref={howItWorksRef}>
            <LightBox alt="instructions" index={activeInstruction}>
              <div className="fade-up">{renderImage()}</div>
            </LightBox>
            <CurrentNumberContainer className="fade-up">
              <CurrentNumberBox>
                <CurrentNumber>{`${activeInstruction}/5`}</CurrentNumber>
              </CurrentNumberBox>
            </CurrentNumberContainer>
          </LeftBox>
          <InstructionsBox>
            {instructions.map(item => {
              return (
                <Instruction
                  key={item.id}
                  onClick={() => scrollToImage(item.id)}
                  onMouseEnter={() => scrolled ? {} : setActiveInstruction(item.id)}
                >
                  <NumberContainer>
                    <NumberBox active={activeInstruction === item.id}>
                      <Number active={activeInstruction === item.id}>
                        {item.id}
                      </Number>
                    </NumberBox>
                  </NumberContainer>
                  <TextContainer>
                    <InstructionTitle active={activeInstruction === item.id}>
                      {item.title}
                    </InstructionTitle>
                    <InstructionDescription
                      active={activeInstruction === item.id}
                    >
                      {item.description}
                    </InstructionDescription>
                  </TextContainer>
                </Instruction>
              )
            })}
            <ArrowBox>
              <ArrowButton disabled={activeInstruction === 1} onClick={goBack}>
                <LeftArrow color={theme.colors.primary} />
              </ArrowButton>
              <ArrowButton disabled={activeInstruction === 5} onClick={goNext}>
                <RightArrow color={theme.colors.primary} />
              </ArrowButton>
            </ArrowBox>
          </InstructionsBox>
        </ContentBox>
      </ContentWrapper>
    </MainWrapper>
  )
}

export default HowItWorks

const MainWrapper = styled.section`
  background: white;
`

const ContentWrapper = styled.div`
  padding: 3rem 0px 3rem 0px;
  max-width: 1280px;
  margin: 0 auto !important;
  float: none !important;

  @media (max-width: 1334px) {
    padding-left: 10%;
    padding-right: 10%;
  }

  @media (min-width: 1024px) {
    padding-top: 120px;
    padding-bottom: 120px;
  }
`

const Title = styled.h2`
  font-size: clamp(1.2rem, 2.5vw, 2rem);
  padding-bottom: 3rem;
  font-family: InterBold;
  text-align: center;
  color: ${({ theme }) => theme.colors.headlineBlack};
`

const ContentBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;

  @media (max-width: 1334px) {
    flex-direction: column;
    gap: 60px;
  }
`

const LeftBox = styled.div`
  display: flex;
  height: 100%;
  width: 70%;
  position: relative;

  @media (max-width: 1334px) {
    width: 100%;
  }
`

const CurrentNumberContainer = styled.div`
  position: absolute;
  bottom: -35px;
  left: 35px;
  display: flex;
  width: auto;
  justify-content: center;
  padding-top: 10px;
  @media (max-width: 1334px) {
    padding-top: 0px;
  }
`

const CurrentNumberBox = styled.div`
  display: flex;
  width: 70px;
  height: 70px;
  border-radius: 80px;
  background-color: ${({ theme }) => theme.colors.primary};
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 0px 8px rgba(24, 197, 149, 0.3);
`

const CurrentNumber = styled.span`
  font-size: 1.2rem;
  font-family: Inter;
  letter-spacing: -0.03em;
  line-height: 1.5;
  text-align: center;
  color: ${({ theme }) => theme.colors.headlineWhite};
`

const InstructionsBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  animation: ${FadeIn} 1.2s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
  @media (max-width: 1334px) {
    gap: 20px;
  }
`

const Instruction = styled.div`
  display: flex;
  height: 18%;
  width: 100%;
  gap: 20px;
  cursor: pointer;
  @media (max-width: 1334px) {
    height: 60px;
  }
`

const NumberContainer = styled.div`
  display: flex;
  width: auto;
  justify-content: center;
  padding-top: 10px;
  @media (max-width: 1334px) {
    padding-top: 0px;
  }
`

const NumberBox = styled.div`
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.primary : theme.colors.gray + "30"};
  justify-content: center;
  align-items: center;

  box-shadow: ${({ active }) =>
    active
      ? "0px 0px 0px 8px rgba(24, 197, 149, 0.3);"
      : "0px 0px 0px 8px rgba(146, 146, 146, 0.1);"};
`

const Number = styled.span`
  font-size: 1.2rem;
  font-family: InterBold;
  letter-spacing: -0.03em;
  line-height: 1.5;
  text-align: center;
  color: ${({ theme, active }) =>
    active ? theme.colors.headlineWhite : theme.colors.primary};
`

const TextContainer = styled.div`
  display: flex;
  width: 80%;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 10px;
`

const InstructionTitle = styled.span`
  width: 100%;
  font-size: clamp(1rem, 2vw, 1.2rem);
  font-family: Inter500;
  letter-spacing: -0.03em;
  line-height: 1.5;
  text-align: left;
  color: ${({ theme, active }) =>
    active ? theme.colors.primary : theme.colors.gray};
`

const InstructionDescription = styled.span`
  width: 100%;
  font-size: clamp(0.4rem, 2vw, 0.8rem);
  font-family: Inter;
  letter-spacing: -0.03em;
  line-height: 1.5;
  text-align: left;
  color: ${({ theme, active }) =>
    active ? theme.colors.fontBlack : theme.colors.gray + "CC"};
`

const ArrowBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`

const ArrowButton = styled.div`
  height: 50px;
  width: 50px;
  cursor: pointer;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  &:hover {
    filter: ${({ disabled }) =>
      disabled ? "brightness(1.1)" : "brightness(1.1)"};
  }
`
