import React, { useEffect } from "react"
import { CustomThemeProvider } from "../theme/ThemeProvider"
import { useMixpanel } from "gatsby-plugin-mixpanel"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import styled, { css } from "styled-components"
import Badge from "../assets/DarkBadge.svg"
import Clock from "../assets/clock.svg"
import Google from "../assets/google.svg"
import Writing from "../assets/writer.svg"
import Testimonials from "../components/Home/Testimonials"
import Seo from "../components/seo"
import HowItWorks from "../components/Home/HowItWorks"
import "swiper/css"
import "swiper/css/pagination"
import { FadeIn } from "../components/animations/FadeIn"
import Plans from "../components/Home/Plans"
import ResultsV2 from "../components/Home/ResultsV2"
import NextSteps from "../components/Home/NextSteps"
import { CustomButton } from "../components/common/Button"
import { Helmet } from "react-helmet"
import Languages from "../components/Languages"
import Sale from "../components/Sale"

export default function Home() {
  const isBrowser = typeof window !== "undefined"
  const mixpanel = useMixpanel()
  const appUrl = process.env.GATSBY_APP_URL
  useEffect(() => {
    const fadeUpObserver = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add("faded")
            fadeUpObserver.unobserve(entry.target)
          }
        })
      },
      {
        threshold: 0.2,
      }
    )

    document.querySelectorAll(".fade-up").forEach(el => {
      fadeUpObserver.observe(el)
    })

    var iframewatcher = setInterval(function () {
      var activeE = document.activeElement
      if (activeE && activeE.tagName === "IFRAME") {
        mixpanel.track("Video clicked")
        clearInterval(iframewatcher)
      }
    }, 100)
  }, [])

  const HandleCTA = (link, name) => {
    //const url = typeof window !== "undefined" ? window.location.href : ""
    mixpanel.track("CTA Button Click", {
      cta: name,
    })
    window.open(link)
  }

  return (
    <CustomThemeProvider>
      <Layout>
        <Seo title={"Cost-Effective Way to Increase SEO Traffic - Robinize"} />
        <Helmet>
          {/* {isBrowser && (
            <>
              <script type="text/partytown">
                {(function (h, o, t, j, a, r) {
                  h.hj =
                    h.hj ||
                    function () {
                      ; (h.hj.q = h.hj.q || []).push(arguments)
                    }
                  h._hjSettings = { hjid: 2919225, hjsv: 6 }
                  a = o.getElementsByTagName("head")[1]
                  r = o.createElement("script")
                  r.async = 2
                  r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
                  a.appendChild(r)
                })(
                  window,
                  document,
                  "https://static.hotjar.com/c/hotjar-",
                  ".js?sv="
                )}
              </script>
              <noscript>
                <img
                  height="2"
                  width="2"
                  style="display:none"
                  src="https://www.facebook.com/tr?id=448854063289846&ev=PageView&noscript=1"
                />
              </noscript>
            </>
          )}
          {isBrowser && (
            <>
              <script type="text/partytown">
                {
                  !(function (e, t, n) {
                    function a () {
                      var e = t.getElementsByTagName("script")[1],
                        n = t.createElement("script")
                        ; (n.type = "text/javascript"),
                          (n.async = !1),
                          (n.src = "https://beacon-v3.helpscout.net"),
                          e.parentNode.insertBefore(n, e)
                    }
                    if (
                      ((e.Beacon = n =
                        function (t, n, a) {
                          e.Beacon.readyQueue.push({
                            method: t,
                            options: n,
                            data: a,
                          })
                        }),
                        (n.readyQueue = []),
                        "complete" === t.readyState)
                    )
                      return a()
                    e.attachEvent
                      ? e.attachEvent("onload", a)
                      : "e.addEventListener("load", a, {passive: true})",
                  })(window, document, window.Beacon || function () { })
                }
              </script>
              <script type="text/partytown">
                {window.Beacon("init", "a6f7ea8c-e801-4ee7-a42b-3cf2d3b815da")}
              </script>
            </>
          )} */}
        </Helmet>

        <MainBackground>
          {/* <Sale/> */}
          <MainWrapper>
            <HeroWrapper>
              <Left>
                <HeadlineContainer>
                  <Headline>
                    Write & Structure{" "}
                    <StyledHeadline>SEO content</StyledHeadline> with AI
                  </Headline>
                  <Description>
                    Enter a phrase you want to rank for on Google, AI does
                    research and writes an SEO-optimized blog. Add your touch
                    and
                    <StyledDescription>
                      {" "}
                      drive organic traffic.
                    </StyledDescription>
                  </Description>
                </HeadlineContainer>
                <ButtonContainer>
                  <StyledLinkTry
                    to={`${appUrl}/register`}
                    color={"white"}
                    aria-label="Register"
                  >
                    <Title aria-hidden="true">Try For Free</Title>
                  </StyledLinkTry>
                  <DemoButton
                    onClick={e => {
                      e.preventDefault()
                      HandleCTA(
                        "https://calendly.com/amar-handzo/30min",
                        "Book a Demo"
                      )
                    }}
                  >
                    Book a Demo
                  </DemoButton>
                </ButtonContainer>
              </Left>
              <Right>
                <VideoContainer>
                  <Video
                    title={"Robinize"}
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;"
                    frameBorder="0"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                    loading="lazy"
                    srcDoc="<style>
                  * {
                    padding:0;
                    margin:0;
                    overflow:hidden;
                  }

                  body, html {
                    height:100%;

                  }
                  img, svg {
                    position:absolute;
                    width:100%;
                    top:0;
                    bottom:0;
                    margin:auto;
                  }

                   body:hover .play {
                    filter: drop-shadow(1px 1px 10px hsla(206.5,0%,10%,0.2));
                    transform: scale(1.02);
                   }

                   .play {
                     bottom:-55%;
                     right:0;
                     height:70%;
                     filter: drop-shadow(1px 1px 10px hsla(206.5,70.7%,10%,0.2));
                     transition: all 250ms ease-in-out;
                   }

                  </style>
                  <a href='https://www.youtube.com/embed/4OuSWbhMkgE?autoplay=1'>
                  <img alt='senad_pic' src='./senad.webp'/>
                  <img alt='video_svg' class='play' src='./videoSVG2.svg'>
                  </a>
                  "
                  />
                </VideoContainer>
                <ProductBadge />
              </Right>
            </HeroWrapper>
            <BenefitsContainer>
              <Benefit delay={"0.5s"}>
                <GoogleContainer>
                  <GoogleSVG />
                </GoogleContainer>
                <BenefitSpan>
                  <StyledSpan google>Rank</StyledSpan> better on Google
                </BenefitSpan>
              </Benefit>
              <Benefit delay={"0.6s"}>
                <ClockContainer>
                  <ClockSVG />
                </ClockContainer>
                <BenefitSpan>
                  <StyledSpan clock>Create</StyledSpan> quality content with AI
                  and our proven know-how
                </BenefitSpan>
              </Benefit>
              <Benefit delay={"0.7s"}>
                <WritingContainer>
                  <WritingSVG />
                </WritingContainer>
                <BenefitSpan>
                  <StyledSpan writer>Optimize</StyledSpan> using our suggestions
                </BenefitSpan>
              </Benefit>
            </BenefitsContainer>
            <Languages />
            <Testimonials />
            <ResultsV2 />
          </MainWrapper>
        </MainBackground>
        <HowItWorks />
        <NextSteps CTAClick={HandleCTA} />
        <Plans CTAClick={HandleCTA} />
      </Layout>
    </CustomThemeProvider>
  )
}

const MainWrapper = styled.div`
  padding: 7% 0px 0px 0px;
  max-width: 1280px;
  margin: 0 auto !important;
  float: none !important;

  @media (max-width: 1334px) {
    padding-left: 10%;
    padding-right: 10%;
  }
`

const MainBackground = styled.div`
  background-image: linear-gradient(to bottom, #11131c, #151836);
  overflow: hidden;
`

const ClockContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  &:before {
    content: "";
    position: absolute;
    top: -20%;
    left: -20%;
    right: 0;
    border-radius: 50%;
    z-index: -1;
    background-color: ${({ theme }) => theme.colors.primary};
    width: 30px;
    height: 30px;
    transition: all 0.4s ease;
  }

  &:hover {
    &:before {
      top: -7%;
      left: -7%;
      height: 60px;
      width: 60px;
    }
  }

  &:hover > * {
    transform: scale(0.8);
  }
`

const ClockSVG = styled(Clock)`
  height: 52px;
  width: 52px;
  transition: transform 0.4s ease;
  will-change: transform;
`

const WritingContainer = styled(ClockContainer)`
  &:before {
    background-color: ${({ theme }) => theme.colors.accentBlue};
  }
`
const WritingSVG = styled(Writing)`
  height: 52px;
  width: 52px;
  transition: transform 0.4s ease;
  will-change: transform;
`

const GoogleContainer = styled(ClockContainer)`
  &:before {
    background-color: ${({ theme }) => theme.colors.accent};
  }
`

const GoogleSVG = styled(Google)`
  height: 52px;
  width: 52px;
  transition: transform 0.4s ease;
  will-change: transform;
`

const ProductBadge = styled(Badge)`
  max-width: 700px;
  width: 100%;
  filter: drop-shadow(1px 1px 10px hsla(206.5, 70.7%, 10%, 0.1));
  opacity: 0;
  animation: ${FadeIn} 1.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s forwards;
`

const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1309px) {
    flex-direction: row;
  }
`
const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 5rem 0;

  @media (min-width: 1309px) {
    width: 100%;
    margin-bottom: 5rem;
  }
`

const HeadlineContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Headline = styled.span`
  font-size: clamp(2.8rem, 5vw, 3.625rem);
  font-family: Inter800;
  margin-bottom: 1.3rem;
  color: ${({ theme }) => theme.colors.headlineWhite};
  position: relative;
  z-index: 1;
  opacity: 0;
  text-align: center;
  animation: ${FadeIn} 1.2s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;

  &:before {
    position: absolute;
    content: "";
    top: -50%;
    left: -5%;
    height: 150px;
    width: 150px;
    background: rgba(24, 197, 149, 0.07);
    border-radius: 90%;
    z-index: -1;
    box-shadow: 0 0 99px 0 rgba(24, 197, 149, 0.2);
    pointer-events: none;
  }

  @media (min-width: 1730px) {
    text-align: left;
  }
`

const StyledHeadline = styled(Headline)`
  color: ${({ theme }) => theme.colors.primary};

  &:before {
    opacity: 0;
  }
`

const Description = styled.span`
  width: 100%;
  font-size: 1.2rem;
  font-family: Inter;
  letter-spacing: -0.03em;
  line-height: 1.5;
  opacity: 0;
  animation: ${FadeIn} 1.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.2s forwards;
  text-align: center;

  @media (min-width: 1730px) {
    max-width: 60ch;
    text-align: left;
  }
`

const StyledDescription = styled.span`
  position: relative;
  font-weight: 700;

  &:before {
    content: "";
    opacity: 1;
    inset: 0;

    z-index: -1;
    position: absolute;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  opacity: 0;
  animation: ${FadeIn} 1.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s forwards;
  justify-content: center;

  @media (min-width: 400px) {
    flex-direction: row;
    gap: 2rem;
  }

  @media (min-width: 1730px) {
    justify-content: flex-start;
  }
`
const TryButton = styled(CustomButton)`
  min-width: 171px;
  height: 52px;
  padding: 14px 26px;
  border-radius: 8px;
  font-size: 1.125rem;
  font-family: ManropeBold;
  transition: all 0.3s ease;
  background-color: ${({ theme }) => theme.colors.primary};

  &:hover {
    filter: brightness(1.1);
  }
`

const DemoButton = styled(CustomButton)`
  position: relative;
  min-width: 171px;
  height: 52px;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 14px 26px;
  border-radius: 8px;
  font-size: 1.125rem;
  font-family: ManropeBold;
  overflow: hidden;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.headlineWhite};
  outline: none;
  transition: color 0.5s, transform 0.2s, background-color 0.2s,
    border 0.5s ease-out;
  border: 2px solid ${({ theme }) => theme.colors.fontWhiteContent};
  text-decoration: none;
  z-index: 2;
  &:active {
    transform: translateY(3px);
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    border: 2px solid ${({ theme }) => theme.colors.primary};
    &:before {
      opacity: 0;
    }
  }

  &:before {
    content: "";
    position: absolute;
    inset: 0;

    transition: opacity 0.3s, border 0.3s;
  }

  &:hover {
    color: white;
    &:after {
      opacity: 1;
      transform-origin: center;
      transform: scale(1) translate(-10px, -70px);
    }
  }

  &:after {
    content: "";
    position: absolute;
    inset: 0;
    width: 260px;
    height: 280px;
    background-color: ${({ theme }) => theme.colors.primary};
    border-color: transparent;
    border-radius: 40%;
    transform: translate(-30px, -70px) scale(0.1);
    opacity: 0;
    z-index: -1;
    transition: transform 0.3s, opacity 0.3s, background-color 0.3s;
  }
`
const StyledLinkTry = styled(Link)`
  text-align: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.headlineWhite};
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px;
  min-width: 171px;
  padding: 14px 16px;
  font-size: 1.125rem;
  font-family: ManropeBold;
  background-color: ${({ theme }) => theme.colors.primary};
`
const Title = styled.span``

const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  width: 100%;
  max-width: 700px;

  @media (min-width: 1309px) {
    padding-left: 5rem;
  }

  @media (max-width: 1000px) {
    height: 100%;
    padding-bottom: 1rem;
  }
`

const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  border-radius: 15px 15px 0 0;
  opacity: 0;
  animation: ${FadeIn} 1.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s forwards;
`

const Video = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  width: 100%;
  height: 100%;
`

const Benefit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 110px;
  border-radius: 15px;
  gap: 2rem;
  opacity: 0;
  animation: ${FadeIn} 1.2s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
  animation-delay: ${({ delay }) => delay};
`

const BenefitsContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 2rem;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 120px;

  @media (min-width: 1024px) {
    margin-top: 7rem;
    flex-direction: row;
  }
`

const BenefitSpan = styled.span`
  font-family: Inter;
  font-size: 1.2rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.colors.headlineWhite};
`

const StyledSpan = styled(BenefitSpan)`
  font-family: Inter500;

  ${({ writer, theme }) =>
    writer ? `color:${theme.colors.accentBlue}` : null};
  ${({ clock, theme }) => (clock ? `color:${theme.colors.primary}` : null)};
  ${({ google, theme }) => (google ? `color:${theme.colors.accent}` : null)};
`
