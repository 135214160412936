import React from "react"
import styled from "styled-components"
import Carousel from "./Carousel/Carousel"
import CarouselItem from "./Carousel/CarouselItem"
import Gb from "../../static/gb.svg"
import Se from "../../static/se.svg"
import De from "../../static/de.svg"
import No from "../../static/no.svg"
import Fr from "../../static/fr.svg"
import It from "../../static/it.svg"
import Es from "../../static/es.svg"
import Pt from "../../static/pt.svg"
import Nl from "../../static/nl.svg"
import Dk from "../../static/dk.svg"
import Cro from "../../static/cro.svg"
import BiH from "../../static/bih.svg"
import Srb from "../../static/srb.svg"

const languagesArray = [
  <img rel="preload" alt="gb" src={Gb} width="60" height="60" />,
  <img rel="preload" alt="se" src={Se} width="60" height="60" />,
  <img rel="preload" alt="de" src={De} width="60" height="60" />,
  <img rel="preload" alt="no" src={No} width="60" height="60" />,
  <img rel="preload" alt="fr" src={Fr} width="60" height="60" />,
  <img rel="preload" alt="it" src={It} width="60" height="60" />,
  <img rel="preload" alt="es" src={Es} width="60" height="60" />,
  <img rel="preload" alt="pt" src={Pt} width="60" height="60" />,
  <img rel="preload" alt="nl" src={Nl} width="60" height="60" />,
  <img rel="preload" alt="dk" src={Dk} width="60" height="60" />,
  <img rel="preload" alt="cro" src={Cro} width="60" height="60" />,
  <img rel="preload" alt="srb" src={Srb} width="60" height="60" />,
  <img rel="preload" alt="bih" src={BiH} width="60" height="60" />,
]

const Languages = () => {
  const mapLanguages = languagesArray.map((language, index) => {
    return <CarouselItem>{language}</CarouselItem>
  })
  return (
    <MainWrapper className="fade-up">
      <Left>
        <Title>13 languages, endless possibilities </Title>
        <Description>
        Get ranked in English, Swedish, German, Norwegian, French, Italian, Spanish, Portuguese, Dutch, Danish, Croatian, Serbian, Bosnian with many more on it’s way!
        </Description>
      </Left>
      <Right>
        <Carousel ItemsPerView={4.3}>{mapLanguages}</Carousel>
      </Right>
    </MainWrapper>
  )
}

export default Languages

const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  max-width: 1280px;
  flex-direction: column;

  @media (min-width: 1130px) {
    flex-direction: row;
  }
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const Title = styled.h2`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.headlineWhite};
`

const Description = styled.span``

const Right = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`
