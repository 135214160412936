import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { CustomButton } from "./Button"

const PlanCard = ({
  name,
  price,
  desc,
  reports,
  projects,
  aiCredits,
  keywordResearch,
  keywordTracking,
  support,
  button,
  priceY,
  multi,
  hasPrice,
  isAnnual,
  isMounted,
  seats,
  planDesc,
  descDiscount,
  highlight,
}) => {
  const [isDiscount, setIsDiscount] = useState(true)
  const appUrl = process.env.GATSBY_APP_URL
  useEffect(() => {
    if (name === "Enterprise") {
      setIsDiscount(false)
    }
  }, [isAnnual, name])

  const handleClick = e => {
    e.preventDefault()
    if (name === "Enterprise") {
      window.open(`https://calendly.com/amar-handzo/30min`)
    }

    if (isAnnual) {
      window.open(appUrl + `/account/billing?plan=${name} Annual`)
    } else {
      window.open(appUrl + `/account/billing?plan=${name} Monthly`)
    }
  }

  return (
    <MainWrapper highlight={highlight}>
      {highlight && (
        <CardExtension isOrange={name !== "Individual"}>
          {name === "Individual"
            ? "For solo content writers"
            : "For content writing teams"}
        </CardExtension>
      )}
      <Card>
        <TitleDescContainer>
          <Title>{name}</Title>
          {isDiscount && name !== "Free" && (
            <DiscountDiv isMounted={isMounted}>
              <DiscountSpan>{multi ? `-18%` : `-15%`}</DiscountSpan>
            </DiscountDiv>
          )}
        </TitleDescContainer>
        <PriceContainer>
          <Price>
            {isAnnual ? priceY : price}
            {hasPrice ? (
              <PriceDesc>{isAnnual ? `/year` : `/month`}</PriceDesc>
            ) : null}
          </Price>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.7rem",
              textAlign: "left",
            }}
          >
            <PlanItem>{seats}</PlanItem>
            <div
              style={{
                display: "flex",
                textAlign: "left",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <Offer>{reports}</Offer>
            </div>
            <PlanItem>{projects}</PlanItem>
            <PlanItem>{aiCredits}</PlanItem>
            <PlanItem>{keywordResearch}</PlanItem>
            <PlanItem>{keywordTracking}</PlanItem>

            <Support support={support}>
              {isDiscount ? "Premium Support" : null}
            </Support>
            {/* 
            {!isAnnual && salePrice && (
              <DiscountDiv isMounted={true} sale={true}>
                <DiscountSpan>30% Off with BlackFriday22</DiscountSpan>
              </DiscountDiv>
            )} */}
          </div>

          <PlanButton
            onClick={handleClick}
            isOrange={highlight && name !== "Individual"}
          >
            {button}
          </PlanButton>
        </PriceContainer>
      </Card>
    </MainWrapper>
  )
}

export default PlanCard

const MainWrapper = styled.div`
  position: relative;
  text-align: center;
  border-radius: ${({ highlight }) =>
    highlight ? "0px 0px 13px 13px" : "13px"};
  margin-top: 3rem;
  width: 270px;
  height: 440px;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid lightgray;
`

const Card = styled.div`
  padding: 20px 30px 20px 30px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`
const TitleDescContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.2rem;
`

const Title = styled.h2`
  text-align: left;
  font-size: 1.1rem;
  font-weight: 500;
  font-family: Inter500;
  color: ${({ theme }) => theme.colors.fontBlack};
  ${({ theme, multi }) =>
    multi ? `color:${theme.colors.headlineWhite}` : null};
`

const PriceContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  min-height: 335px;
`

const PlanItem = styled.span`
  color: ${({ theme, multi }) =>
    multi ? theme.colors.headlineWhite : theme.colors.fontBlack};
  font-family: Inter500;
`

const Price = styled.span`
  font-size: 1.7rem;
  padding-bottom: 1rem;
  font-family: InterBold;
  text-align: left;
  color: ${({ theme }) => theme.colors.fontBlack};

  ${({ theme, multi }) =>
    multi ? `color:${theme.colors.headlineWhite}` : null};
`

// const SaleText = styled.p`
//   display: inline-block;
//   font-size: 0.7rem;
//   font-family: InterBold;
//   color: ${({ theme }) => theme.colors.fontBlack};
// `

const PriceDesc = styled.span`
  font-size: 1rem;
  font-family: Inter;
  ${({ theme, multi }) =>
    multi ? `color:${theme.colors.headlineWhite}` : null};
`

const Offer = styled.span`
  color: ${({ theme }) => theme.colors.fontBlack};
  font-size: 1rem;
  font-family: Inter500;

  ${({ multi, theme }) =>
    multi ? `color:${theme.colors.headlineWhite}; ` : null}
`

const OfferDescription = styled.span`
  color: ${({ theme }) => theme.colors.gray};
  font-size: 0.9rem;
  font-family: Inter;
  ${({ multi, theme }) =>
    multi ? `color:${theme.colors.fontWhiteContent};` : null}
`

const DiscountSpan = styled.span`
  font-size: 15px;
  color: #fff;
  font-family: Inter;
`

const CardExtension = styled.div`
  position: absolute;
  height: 30px;
  width: 100%;
  top: -25px;
  left: -1px;
  border-radius: 13px 13px 0px 0px;
  background-color: ${({ theme, isOrange }) =>
    isOrange ? theme.colors.accent : theme.colors.primary};
  border: 1px solid
    ${({ theme, isOrange }) =>
      isOrange ? theme.colors.accent : theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`

const DiscountDiv = styled.div`
  padding: 0px 20px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  opacity: 0;
  transition: all 0.4s ease-out;
  background-color: ${({ theme, multi }) =>
    multi ? "#272932" : theme.colors.accent};
  ${({ isMounted }) => (isMounted ? `opacity : 1` : null)};

  white-space: nowrap;
`

const Support = styled.span`
  color: ${({ theme }) => theme.colors.accent};
  font-family: Inter;

  ${({ support }) =>
    !support ? `color:gray;text-decoration: line-through;` : null};

  ${({ multi, theme }) => (multi ? ` color:#fff; border:none` : null)};
`

const PlanButton = styled(CustomButton)`
  width: 100%;
  height: 50.7px;
  font-size: 17px;
  font-family: Inter500;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.headlineWhite};
  background-color: ${({ theme, isOrange }) =>
    isOrange ? theme.colors.accent : theme.colors.primary};
  margin-top: auto;
  transition: all 0.4s ease;

  ${({ multi, theme }) =>
    multi ? `background-color:#272932;color:#fff; border:none` : null};

  &:hover {
    filter: brightness(1.1);
    ${({ multi, theme }) =>
      multi ? `background-color:#fff;color:#272932; border:none` : null};
  }
`
