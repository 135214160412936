import React from "react"
import styled from "styled-components"

const CarouselItem = ({ children, width }) => {
  return <Item style={{ width: width }}>{children}</Item>
}

export default CarouselItem

const Item = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  color: #fff;
`
