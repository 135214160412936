import React from "react"
import { useEffect, useState } from "react"
import styled from "styled-components"
import BlackFriday from "../../static/BlackFriday.svg"

const Sale = () => {
  const [isMobile, setIsMobile] = useState(false)
  const registerUrl = process.env.GATSBY_APP_URL + "/register"
  const handleBannerClick = e => {
    e.preventDefault()
    window.open(registerUrl)
  }

  useEffect(() => {
    if (window.innerWidth < 1000) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [])

  return (
    <Banner>
      <BannerContent
        src={BlackFriday}
        isMobile={isMobile}
        onClick={handleBannerClick}
      />
    </Banner>
  )
}

export default Sale

const Banner = styled.div`
  padding-top: 120px;
  width: 100vw;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -50px;
`

const BannerContent = styled.img`
  width: 60%;
  scale: ${({ isMobile }) => (isMobile ? 4 : 2)};
  border: none;
  cursor: pointer;
`
