import React from "react"
import { useEffect } from "react"
import { useState } from "react"
import { useSwipeable } from "react-swipeable"
import styled from "styled-components"
import LeftArrow from "../../assets/left-arrow.svg"
import RightArrow from "../../assets/right-arrow.svg"

const Carousel = ({ children, ItemsPerView }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [paused, setPaused] = useState(false)

  // useEffect to transition activeIndex every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        updateIndex(activeIndex + 1)
      }
    }, 8000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  })

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      updateIndex(activeIndex + 1)
    },
    onSwipedRight: () => {
      updateIndex(activeIndex - 1)
    },
  })

  const updateIndex = newIndex => {
    if (newIndex < 0) {
      newIndex = ItemsPerView - 1
    } else if (newIndex >= ItemsPerView) {
      newIndex = 0
    }

    setActiveIndex(newIndex)
  }

  return (
    <SectionWrapper>
      <MainWrapper
        {...swipeHandlers}
        onMouseEnter={() => {
          setPaused(true)
        }}
        onMouseLeave={() => {
          setPaused(false)
        }}
      >
        <InnerCarousel
          style={{ transform: `translate(-${activeIndex * 50}%)` }}
        >
          {React.Children.map(children, (child, index) => {
            return React.cloneElement(child, { width: "23%" })
          })}
        </InnerCarousel>
        {}
        <Indicators>
          <LeftButton
            aria-label="left-arrow"
            onClick={e => {
              e.preventDefault()
              updateIndex(activeIndex - 1)
            }}
          >
            <LeftArrow />
          </LeftButton>
          <RightButton
            aria-label="right-arrow"
            onClick={e => {
              e.preventDefault()
              updateIndex(activeIndex + 1)
            }}
          >
            <RightArrow />
          </RightButton>
        </Indicators>
      </MainWrapper>
    </SectionWrapper>
  )
}

export default Carousel

const SectionWrapper = styled.div`
  position: relative;
  width: 300px;
  @media (min-width: 600px) {
    width: 500px;
  }
`

const MainWrapper = styled.div`
  overflow: hidden;
  isolation: isolate;
`

const InnerCarousel = styled.div`
  white-space: nowrap;
  transition: transform 0.5s ease;
`

const Indicators = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  inset: 0;

  > * {
    background: none;
    border: none;
    width: 40px;
    height: 10px;
    color: white;
    z-index: 30;
    cursor: pointer;
    opacity: 0.8;
  }
`

const LeftButton = styled.button`
  position: absolute;
  top: 40%;
  left: -45px;
  height: 40px;

  @media (min-width: 600px) {
    left: -20px;
  }
  overflow: visible;
`

const RightButton = styled.button`
  position: absolute;
  top: 40%;
  right: -45px;
  height: 40px;
  @media (min-width: 600px) {
    right: -20px;
  }
  overflow: visible;
`
