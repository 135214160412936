import React from "react"
import styled from "styled-components"
import { Title } from "../common/Title"
import Demo from "../../assets/demo.svg"
import Clock from "../../assets/clocklast.svg"
import Home from "../../assets/home.svg"
import { CustomButton } from "../common/Button"

const NextSteps = ({ CTAClick }) => {
  return (
    <Background>
      <MainWrapper>
        <TitleWrapper className="fade-up">
          <Title>Next Steps</Title>
          <SubTitle>
            Our team of experts is here to help you reach your goals
          </SubTitle>
        </TitleWrapper>
        <CenterDiv className="fade-up">
          <ItemDiv>
            <IconContainer>
              <Demo />
            </IconContainer>
            <ItemText>Book a demo call with us</ItemText>
          </ItemDiv>
          <ItemDiv>
            <IconContainer>
              <Home />
            </IconContainer>
            <ItemText>
              Learn how to use Robinize and integrate it into your business
              model
            </ItemText>
          </ItemDiv>
          <ItemDiv>
            <IconContainer>
              <Clock />
            </IconContainer>
            <ItemText>
              Outperform the competition and grow your organic traffic
            </ItemText>
          </ItemDiv>
        </CenterDiv>
        {/* <ButtonContainer className="fade-up">
          <DemoButton
            onClick={(e) => {
              e.preventDefault()
              CTAClick(
                "https://calendly.com/haris-husejnovic/robinize-demo",
                "Book a demo / Steps"
              )
            }}
          >
            Book a demo with our experts
          </DemoButton>
        </ButtonContainer> */}
      </MainWrapper>
    </Background>
  )
}

export default NextSteps

const Background = styled.section`
  max-height: 100%;
`

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  padding: 3rem 0px 12rem 0px;
  max-width: 1280px;
  margin: 0 auto !important;
  float: none !important;

  @media (min-width: 1024px) {
    padding-top: 120px;
  }

  @media (max-width: 1334px) {
    padding-left: 10%;
    padding-right: 10%;
  }

  @media (min-width: 1584px) {
    padding-bottom: 16rem;
  }
`

const TitleWrapper = styled.div``

const SubTitle = styled.h2`
  font-size: 1.2rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.headlineWhite};
`

const CenterDiv = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
  min-height: 400px;
  gap: 1rem;
  margin-top: 2rem;
  text-align: center;
  flex-direction: column;

  @media (min-width: 750px) {
    flex-direction: row;
    gap: 3rem;
  }
`

const IconContainer = styled.div`
  width: 120px;
  height: 120px;
  max-height: 120px;
  max-width: 120px;
  border: solid 9px #1f2449;
  border-radius: 50%;
  background-image: linear-gradient(226deg, #212651 2%, #161a33 99%);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-grow: 3;

  &:after {
    background-image: linear-gradient(226deg, #212651 2%, #161a33 99%);
    position: absolute;
    width: 116%;
    height: 116%;
    content: "";
    border-radius: 50%;
    z-index: -1;
  }
`

const ItemDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 3rem;
  flex: 1;
  min-height: 350px;
`

const ItemText = styled.span`
  font-weight: Inter500;
  font-size: 1.3rem;
  flex-grow: 1;
  color: ${({ theme }) => theme.colors.headlineWhite};
`

const ButtonContainer = styled.div``

const DemoButton = styled(CustomButton)`
  max-width: 300px;
  height: 60px;
  padding: 20px;
  box-shadow: 0 3px 5px 0 rgba(217, 83, 49, 0.15);
  background-color: #18c595;
  font-family: Inter;
  border-radius: 8px;
  font-size: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;

  &:hover {
    filter: brightness(1.1);
  }
`
